import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import NavContext from '../data/NavContext';

export default function Banner({children, className}) {
  const {isBannerVisible} = useContext(NavContext);

  if (isBannerVisible) {
    return <div className={className}>{children}</div>;
  }
  return null;
}

Banner.defaultProps = {
  children: null,
  className: null,
};
Banner.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export const BannerPlasmicData = {
  name: 'Nav.Banner',
  props: {
    children: {
      type: 'slot',
      defaultValue: [
        {
          type: 'component',
          name: 'Nav.HideBannerButton',
        },
      ],
    },
  },
  defaultStyles: {
    position: 'relative',
    width: '100vw',
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FF0051',
  },
  importPath: './components/NavBar/Banner',
  isDefaultExport: true,
};
