import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import NavContext from '../data/NavContext';

export default function HideBannerButton({children, className}) {
  const {setIsBannerVisible} = useContext(NavContext);
  return (
    <button type="button" onClick={() => setIsBannerVisible(false)} className={className}>
      {children}
    </button>
  );
}

HideBannerButton.defaultProps = {
  className: null,
  children: null,
};
HideBannerButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
export const HideBannerButtonPlasmicData = {
  name: 'Nav.HideBannerButton',
  props: {
    children: {
      type: 'slot',
      defaultValue: {
        type: 'img',
      },
    },
  },
  defaultStyles: {
    position: 'absolute',
    right: '2.25rem',
    top: '4rem',
    display: 'flex',
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '9999px',
    backgroundColor: '#4A4A4B',
    transform: 'translateY(-50%)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  importPath: './components/NavBar/Banner/HideBannerButton',
  isDefaultExport: true,
};
